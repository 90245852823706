import React from 'react'

import BlogContainer from "../BlogContainer/BlogContainer"
import BlogDetail from "../../pages/BlogDetail"
import NavBar from "../NavBar/NavBar"
import "../../styles/Blog.scss"


function Articles () {
    return (
        <div>
            <BlogContainer />
        </div>
    )
}

export default Articles;