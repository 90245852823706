import React from 'react'

import BlogContainer7 from "../BlogContainer/BlogContainer7"
import "../../styles/Blog.scss"


function Articles7   () {
    return (
        <div >
            <BlogContainer7 />
        </div>
    )
}

export default Articles7;