import React from "react";
import "../../styles/BlogDetail.scss";
import defaultBlog from "../../img/default-blog.png";

// this is the actual article page
const BlogDetailTile = (props) => {
  console.log("props.blogData", props.post);
  const post = props.post[0];

  function blogPost() {
    const image = post._embedded["wp:featuredmedia"]
      ? post._embedded["wp:featuredmedia"]["0"].source_url
      : defaultBlog;

    return (
      <>
        <div className="blog-post">
          <div className="ImageContainer">
            <img
              src={`${image}`}
              className="Image"
              alt={truncateText(cleanTitle(post.title.rendered), 0, 60)}
            />
          </div>
          <p id='post-date' className="blog-p">{convertDate(post.date)}</p>

          <div className="TDContainer">
            <p
              className="blog-p"
              dangerouslySetInnerHTML={{ __html: post.content.rendered }}
            ></p>
            <br />
          </div>
        </div>
      </>
    );
  }

  function cleanTitle(checkTitle) {
    checkTitle = checkTitle.replace("amp;", "");
    return checkTitle;
  }

  function truncateText(text, start, len) {
    return text.length > len ? text.slice(start, len) : text;
  }

  function toText(block) {
    let tag = document.createElement("div");
    tag.innerHTML = block;
    block = tag.innerText;
    return block;
  }

  function convertDate(date) {
    let dateArray = date.slice(0, 10).split("-");
    let year = dateArray.shift();
    dateArray.push(year);
    console.log(date);
    return `Published: ${dateArray.join("/")}`;
  }

  return (
    <>

      <div className="inner-PostContainer">{blogPost()}</div>
    </>
  );
};

export default BlogDetailTile;