import React from 'react'

import BlogContainer9 from "../BlogContainer/BlogContainer9"
import "../../styles/Blog.scss"


function Articles9   () {
    return (
        <div >
            <BlogContainer9 />
        </div>
    )
}

export default Articles9;