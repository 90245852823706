import React from "react";
import "../../styles/Blog.scss"
import { Link } from "react-router-dom";
import defaultBlog from "../../img/default-blog.png"
import { BsArrowRight } from "react-icons/bs";

// this is the home page article preview
const BlogTile = (props) => {
  const post = props.post;

  function blogPost() {
    const image = post._embedded["wp:featuredmedia"]
      ? post._embedded["wp:featuredmedia"]["0"].source_url
      : defaultBlog;
    return (
      <div className='blog-tile-container' >
        <a className='blog-tile-link' href={`/articles/${post.slug}`} rel="noopener">
          <div className='blog-tile-title-container'>
        <h3
        className='blog-tile-header'
            

            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          >

          </h3>
                   <p
              className="blog-tile-description"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            ></p>

             {/* <p className='read-more-btn'>
             <a className='read-more-link' href={`/articles/${post.slug}`} rel="noopener">

               Read More <BsArrowRight />
               </a>
               </p> */}
       
             </div>
          <div className='blog-tile-img-container' >
            <img
            className='blog-tile-img'
              src={`${image}`}
             
              alt={truncateText(cleanTitle(post.title.rendered), 0, 10)}
            />
          </div>
          </a>

      </div>
    );
  }

  function cleanTitle(checkTitle) {
    checkTitle = checkTitle.replace("amp;", "");
    return checkTitle;
  }

  function truncateText(text, start, len) {
    return text.length > len ? text.slice(start, len) : text;
  }

  function toText(block) {
    let tag = document.createElement("div");
    tag.innerHTML = block;
    block = tag.innerText;
    return block;
  }

  function convertDate(date) {
    let dateArray = date.slice(0, 10).split("-");
    let year = dateArray.shift();
    dateArray.push(year);
    return `Published: ${dateArray.join("/")}`;
  }

  return (
    <div >
      {blogPost()}
    </div>
  );
};

export default BlogTile;