import React from 'react'

import BlogContainer6 from "../BlogContainer/BlogContainer6"
import "../../styles/Blog.scss"


function Articles6   () {
    return (
        <div >
            <BlogContainer6 />
        </div>
    )
}

export default Articles6;