import React from 'react'

import BlogContainer2 from "../BlogContainer/BlogContainer2"
import "../../styles/Blog.scss"


function Articles2 () {
    return (
        <div >
            <BlogContainer2 />
        </div>
    )
}

export default Articles2;