import React, { useEffect  } from 'react';

const HorizontalAd = (props) => {
    const { dataAdSlot } = props;  



    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {

        }

    },[]);


// Horizontal ad
    return (
        <>
            {/* <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-XXXXXXXXXXXXXXX"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins> */}
            <ins class="adsbygoogle"
 style={{ display: "block" }}     
 data-ad-client="ca-pub-3558678928068525"
     data-ad-slot="3760120338"
     data-ad-format="auto"
     data-adtest="on"
     data-full-width-responsive="true"></ins>
        </>
    );
};

export default HorizontalAd;