import React, { useState, useEffect } from "react";
import BlogDetailTile from "../components/BlogDetailTile/BlogDetailTile";
import "../styles/BlogDetail.scss"
import { useParams } from "react-router";
import BlogTile from "../components/BlogTile/BlogTile";
import BlogTileSide from "../components/BlogTileSide/BlogTileSide";
import NavBar from "../components/NavBar/NavBar"


const BlogDetail = () => {
  // const mediumUrl = "https://medium.com/feed/@joshuagauthreaux"
  const { slug } = useParams();
  const rss2json =
      "https://otcfantasy.wpcomstaging.com/wp-json/wp/v2/posts?_embed&";

    // "https://gemint390292500.wpcomstaging.com/wp-json/wp/v2/posts?_embed&";
  const [post, setPost] = useState('');
  const [blog, setBlog] = useState('');
  const [posts, setPosts] = useState([]);
  const [sidePosts, setSidePosts] = useState('');
  const [topPosts, setTopPosts] = useState('');

  useEffect(() => {
    fetch(rss2json + "slug=" + slug)
      .then((res) => res.json())
      .then((data) => {
        console.log("data[0].yoast_head_json", data[0].yoast_head_json);
//         if (data[0].yoast_head_json) {
//           document.title = data[0].yoast_head_json.title;
//           document.querySelector('meta[property="og:description"]')
// .setAttribute("content", data[0].yoast_head_json.og_description);
//           document
//             .querySelector('meta[property="og:locale"]')
//             .setAttribute("content", data[0].yoast_head_json.og_locale);
//           document
//             .querySelector('meta[property="og:type"]')
//             .setAttribute("content", data[0].yoast_head_json.og_type);
//           document
//             .querySelector('meta[property="og:title"]')
//             .setAttribute("content", data[0].yoast_head_json.og_title);
//           document
//             .querySelector('meta[property="og:site_name"]')
//             .setAttribute("content", data[0].yoast_head_json.og_site_name);
//           document
//             .querySelector('meta[property="og:url"]')
//             .setAttribute("content", data[0].yoast_head_json.og_url);
//           document
//             .querySelector('meta[property="description"]')
//             .setAttribute("content", data[0].yoast_head_json.description);
//           document
//             .querySelector('meta[property="author"]')
//             .setAttribute("content", data[0].yoast_head_json.author);
//         }
        setBlog(data[0]);
        setPost(<BlogDetailTile post={data} />);
        window.scrollTo(0, 0);
        if (data[0].categories) {
          getRelatedPosts(data[0].categories.join(), data[0].id);
        }
        getTopPosts(data[0].id);
      });
  }, [slug]);

  function getRelatedPosts(categories, id) {
    fetch(rss2json + "per_page=3&categories=" + categories + "&exclude=" + id)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPosts(
            data.map((blog) => {
              return <BlogTile key={blog.id} post={blog} />;
            })
          );
          setSidePosts(
            data.map((blog) => {
              return <BlogTileSide key={blog.id} post={blog} />;
            })
          );
        }
      });
  }

  function getTopPosts(id) {
    fetch(rss2json + "per_page=3&categories=375&exclude=" + id)
      .then((res) => res.json())
      .then((data) => {
        setTopPosts(
          data &&
            data.map((blog) => {
              return <BlogTileSide key={blog.id} post={blog} />;
            })
        );
      });
  }

  return (
    <>
    <NavBar />
      <div className="blog-detail-container">
          <h1
              className="blog-header"
              dangerouslySetInnerHTML={{ __html: blog && blog.title ? blog.title.rendered : null}}
          ></h1>
        <div className="inner-PostContainer">
          {post}
          <div className="sidebar-container">
            <div className="related-news-sidebar">
              <div className="sidebar-content">
                <h2 className="trending-h">Related Articles</h2>
                <div className="related-news">{sidePosts}</div>
              </div>
            </div>
            {/* <div className="related-news-sidebar top-news mt-4">
              <div className="sidebar-content">
                <h2 className="sidebar-head">Top News</h2>
                <div className="related-news">{topPosts}</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <h1 className="blog-header">Related News</h1>
      <div className="blog-container">{posts}</div> */}
    </>
  );
};

export default BlogDetail;
