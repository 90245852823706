import React from 'react'

import BlogContainer10 from "../BlogContainer/BlogContainer10"
import "../../styles/Blog.scss"


function Articles10   () {
    return (
        <div >
            <BlogContainer10 />
        </div>
    )
}

export default Articles10;