import "./styles/App.css"
import React from 'react'

import {
  BrowserRouter as Router,
 Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"

import BlogDetail from "./pages/BlogDetail"
import Home from "./pages/Home"
import Helmet from "react-helmet";
import Favicon16 from "./img/favicon-16x16.png";
import Favicon32 from "./img/favicon-32x32.png";
import ReactGA from "react-ga";

const trackingId = "G-MY6XD5P2D4"; 

ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  return (
    <div className="App">
 <Router>
      <div>
     
        <Routes>
        <Route exact path='/' element={<Home/>} />
        {/* <Route exact path='/articles' element={<Articles/>} /> */}

              <Route exact path="/articles/:slug" element={<BlogDetail/>} />
    
        </Routes>
      </div>
    </Router>
    <Helmet>
        <title>Suns Sickos</title>
        <meta name="description" content="Phoenix Suns fans" />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
      </Helmet>
    </div>

  );
}

export default App;