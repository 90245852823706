import React, { useState, useEffect } from "react";
import BlogTile3 from "../BlogTile/BlogTile3"
import "../../styles/Blog.scss"
import { Pagination } from "react-bootstrap";

const BlogContainer3 = () => {
  // const mediumUrl = "https://medium.com/feed/@joshuagauthreaux"
  const rss2json =
    "https://otcfantasy.wpcomstaging.com/wp-json/wp/v2/posts?_embed&per_page=9&page=";

  const [posts, setPosts] = useState([]);

  const [page, setPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [pagination, setPagination] = useState([]);

  const [firstPost, setFirstPost] = useState([]);

  useEffect(() => {
    setShowLoader(true);
    fetch(rss2json + page)
      .then((res) => {
        getPagination(res.headers.get("X-WP-TotalPages"));
        return res.json();
      })
      .then((data) => {
        listPosts(data);
        setShowLoader(false);
        window.scrollTo(0, 0);
      });
  }, [page]);

  function listPosts(posts) {
    setPosts(
      posts &&
        posts.map((blog) => {

          return <BlogTile3 key={blog.id} post={blog} />;
        })
    );
  }


  function pageLink(i, label) {
    return (
      <span
        key={label ?? i}
        onClick={() => setPage(i)}
        className={i === page ? "active" : "page"}
        style={{
          padding: "15px",
          color: "#fff",
          fontSize: "25px",
          cursor: "pointer",
        }}
      >
        {label ?? i}
      </span>
    );
  }
  function getPagination(totalPage) {
    let links = [],
      start = 1,
      end = totalPage,
      maxPage = 5;

    if (totalPage > maxPage) {
      end = maxPage + page < totalPage ? maxPage + page : totalPage;

      if (page + maxPage > totalPage) {
        start = totalPage - maxPage;
      } else {
        start = page;
      }
    }

    if (page > 1) {
      links.push(pageLink(1, "First"));
      links.push(pageLink(page - 1, "Previous"));
    }

    for (let i = start; i <= end; i++) {
      links.push(pageLink(i));
    }

    if (page < totalPage) {
      links.push(pageLink(page + 1, "Next"));
      links.push(pageLink(parseInt(totalPage), "Last"));
    }
    setPagination(links);
  }



  return (
    <div className='blog-container-3' >
              <h1 className='latest-news-h'>Latest News </h1>

    <div>

<div className='trending-post'>{posts[0]}</div>
<div className='trending-post'>{posts[0]}</div>
<div className='trending-post'>{posts[0]}</div>
<div className='trending-post'>{posts[0]}</div>
<div className='trending-post'>{posts[0]}</div>
<div className='trending-post'>{posts[0]}</div>
<div className='trending-post'>{posts[0]}</div>



{/* <div className='fifth-post'>{posts[4]}</div> */}
      {/* <div className="blog-container">{posts}</div> */}
      {/* <div className="pagination">
        {showLoader && (
          <img src={loader} alt={"Loading..."} className="pagination-loader" />
        )}
        {pagination}
      </div> */}
    </div>
    </div>

  );
};

export default BlogContainer3;