import React from 'react'

import BlogContainer4 from "../BlogContainer/BlogContainer4"
import "../../styles/Blog.scss"


function Articles4 () {
    return (
        <div >
            <BlogContainer4 />
        </div>
    )
}

export default Articles4;