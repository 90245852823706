import React from "react";
import "../../styles/Blog.scss"
import { Link } from "react-router-dom";
import defaultBlog from "../../img/default-blog.png";
import { BsArrowRight, BsCircleFill } from "react-icons/bs";
// this is the home page article preview
const BlogTile3 = (props) => {
  const post = props.post;

  function blogPost() {
    const image = post._embedded["wp:featuredmedia"]
      ? post._embedded["wp:featuredmedia"]["0"].source_url
      : defaultBlog;
    return (
      <div >
        <a className='blog-tile-3-link' href={`/articles/${post.slug}`} rel="noopener">
        <div  >
      <div className='blog-tile-3-wrapper'  >
      <BsCircleFill size={7} /><h3 className='blog-tile-3-h'
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            >

            </h3>
            {/* <button className="main-blog-p">
              CONTINUE READING  <BsArrowRight color='white' size={25}/>
            </button> */}
            {/* <p
              className="main-blog-p"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            ></p> */}
                      </div>
                      {/* <div className='main-img-wrapper'>

                  <img
              src={`${image}`}
        
              alt={truncateText(cleanTitle(post.title.rendered), 0, 10)}
              id='main-img'
            />
            </div> */}
        
            </div>

            {/* <br />
            <p className="blog-p">{convertDate(post.date)}</p> */}
        </a>
      </div>
    );
  }

  function cleanTitle(checkTitle) {
    checkTitle = checkTitle.replace("amp;", "");
    return checkTitle;
  }

  function truncateText(text, start, len) {
    return text.length > len ? text.slice(start, len) : text;
  }

  function toText(block) {
    let tag = document.createElement("div");
    tag.innerHTML = block;
    block = tag.innerText;
    return block;
  }

  function convertDate(date) {
    let dateArray = date.slice(0, 10).split("-");
    let year = dateArray.shift();
    dateArray.push(year);
    return `Published: ${dateArray.join("/")}`;
  }

  return (
    <div >
      {blogPost()}
    </div>
  );
};

export default BlogTile3;