import React from 'react'
import NavBar from "../components/NavBar/NavBar"
import Articles from "../components/Articles/Articles"
import Articles2 from "../components/Articles/Articles2"
import Articles3 from "../components/Articles/Articles3"
import Articles4 from "../components/Articles/Articles4"
import Articles5 from "../components/Articles/Articles5"
import Articles6 from "../components/Articles/Articles6"
import Articles7 from "../components/Articles/Articles7"
import Articles8 from "../components/Articles/Articles8"
import Articles9 from "../components/Articles/Articles9"

import Articles10 from "../components/Articles/Articles10"


import HorizontalAd from '../components/Ads/HorizontalAd';


import "../styles/Home.scss"
import Slider from "react-slick";


function Home () {
   
    return (
        <div className='home-container'>
            <NavBar />
     
            <Articles />

            <div className='second-section'>
                <div className='main-article'>
            <Articles2 />
            </div>
            <div className='latest-articles'>
            <Articles3 />
            </div>
            </div>

            <div className='section-4'>
            <Articles4 />
            </div>
            <div className='horizontal-ad-1'>
            <HorizontalAd dataAdSlot='3760120338' />
            </div>
            <div className='section-5'>
            <Articles5 />
            </div>
            <div className='wrapper-6-7'>
            <div className='section-6'>
            <Articles6 />
            </div>
            <div className='section-7'>
            <Articles7 />
            </div>
            </div>
            <div className='section-5'>
            <Articles8 />
            </div>
            <div className='section-4'>
            <Articles9 />
            </div>

            <div className='section-5'>
            <Articles10 />
            </div>

        </div>
    )
}

export default Home;