import React from "react"
import { Link, useHistory } from "react-router-dom";
import "../../styles/NavBar.scss"
import { FiInstagram, FiTwitter } from 'react-icons/fi';


function NavBar () {
    return (
        <div className='navbar-container'>
              <div className='logo-container'>
            <Link to="/" className="navbar-logo">
              <h1 className='logo-header'>Suns</h1>
              <h1 className='logo-header2'>Sickos</h1>


        </Link>
</div>
<div className='icons-wrapper'>
        <a target="_blank" href="https://www.instagram.com/otc_fantasy/">
<FiInstagram className='icon-1' size={25} color='white' />
</a>
<a target="_blank" href="https://www.twitter.com/otc_fantasy/">

<FiTwitter className='icon-2' size={25} color='white' />
</a>


</div>
        </div>
    )
}

export default NavBar;