import React from 'react'

import BlogContainer5 from "../BlogContainer/BlogContainer5"
import "../../styles/Blog.scss"


function Articles5   () {
    return (
        <div >
            <BlogContainer5 />
        </div>
    )
}

export default Articles5;