import React from 'react'

import BlogContainer8 from "../BlogContainer/BlogContainer8"
import "../../styles/Blog.scss"


function Articles8   () {
    return (
        <div >
            <BlogContainer8 />
        </div>
    )
}

export default Articles8;